<template>
  <TransitionRoot as="template" :show="sidebarOpen">
    <Dialog as="div" static class="fixed inset-0 flex z-40 md:hidden" @close="sidebarOpen = false" :open="sidebarOpen">
      <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100" leave-to="opacity-0">
        <DialogOverlay class="fixed inset-0 bg-gray-600 bg-opacity-75" />
      </TransitionChild>
      <TransitionChild as="template" enter="transition ease-in-out duration-300 transform" enter-from="-translate-x-full" enter-to="translate-x-0" leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0" leave-to="-translate-x-full">
        <div class="relative flex-1 flex flex-col max-w-xs w-full bg-white">
          <TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-300" leave-from="opacity-100" leave-to="opacity-0">
            <div class="absolute top-0 right-0 -mr-12 pt-2">
              <button class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" @click="sidebarOpen = false">
                <span class="sr-only">Close sidebar</span>
                <XIcon class="h-6 w-6 text-white" aria-hidden="true" />
              </button>
            </div>
          </TransitionChild>
          <div class="flex-1 h-0 pb-4 overflow-y-auto">
            <nav class="px-2 space-y-1">
              <a v-for="item in navigation" :key="item.name" :href="item.href" :class="[item.current ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900', 'group flex items-center px-2 py-2 text-base font-medium rounded-md']">
                <component :is="item.icon" :class="[item.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500', 'mr-4 flex-shrink-0 h-6 w-6']" aria-hidden="true" />
                {{ item.name }}
              </a>
            </nav>
          </div>
        </div>
      </TransitionChild>
      <div class="flex-shrink-0 w-14">
        <!-- Force sidebar to shrink to fit close icon -->
      </div>
    </Dialog>
  </TransitionRoot>

  <!-- Static sidebar for desktop -->
  <div class="hidden md:flex md:flex-shrink-0">
    <div class="flex flex-col w-64">
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div class="flex flex-col h-0 flex-1 bg-white">
        <div class="flex-1 flex flex-col pb-4 overflow-y-auto">
          <nav class="flex-1 px-2 bg-white space-y-1">
            <router-link v-slot="{ isActive }" active-class="bg-gray-100 text-gray-900" v-for="item in navigation" :key="item.name" :to="item.href" class="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-sm font-medium rounded-md">
              <component :is="item.icon" :class="[isActive ? 'text-system-blue' : 'hover:text-system-dark-blue', 'mr-3 flex-shrink-0 h-6 w-6 fill-current text-system-blue']" aria-hidden="true" />
              {{ item.name }}
            </router-link>
            <div class="flex-grow"></div>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {Options, prop, Vue} from "vue-class-component";
import {MenuIcon, XIcon} from "heroicons-vue3/outline";
import {Dialog, DialogOverlay, TransitionChild, TransitionRoot} from '@headlessui/vue'
import {EdisCloudIcon, EdisDashboardIcon, EdisEarthIcon, EdisMailIcon, EdisRackIcon, EdisWandIcon} from '@/common/icons'

class Props {
  sidebarOpen = prop<boolean>({ default: false })
}

@Options({
  components: {
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
    MenuIcon,
    XIcon,
    EdisDashboardIcon,
    EdisMailIcon
  },
  watch: {
    sidebarOpen: value => {
      console.log("sidebar open", value);
    }
  }
})
export default class Sidebar extends Vue.with(Props) {
  navigation = [
    { name: 'Dashboard', href: '/dashboard', icon: EdisDashboardIcon },
    { name: 'E-Mail', href: '/email', icon: EdisMailIcon },
    { name: 'Installer', href: '/installer', icon: EdisWandIcon },
    { name: 'Webspace', href: '/webspace', icon: EdisRackIcon },
    { name: 'Domains', href: '/domains', icon: EdisEarthIcon },
    { name: 'S3', href: '/amazon', icon: EdisCloudIcon },
  ]
}
</script>

<style scoped>

</style>
