<template>
  <Header></Header>

  <div class="h-screen flex overflow-hidden bg-gray-100">
    <Sidebar :sidebarOpen="sidebarOpen"></Sidebar>

    <div class="flex flex-col w-0 flex-1 overflow-hidden">
      <div class="md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3">
        <button class="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500" @click="sidebarOpen = true">
          <span class="sr-only">Open sidebar</span>
          <MenuIcon class="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
      <main class="flex-1 relative z-0 overflow-y-auto focus:outline-none">
        <div class="py-6">
          <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <div class="py-4 text-left">
              <router-view></router-view>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script lang="ts">
import {Options, Vue} from "vue-class-component";
import { Header } from "@/common/components";
import Sidebar from "@/modules/sidebar/components/Sidebar.vue";
import {MenuIcon} from "heroicons-vue3/outline";

@Options({
  components: {
    Header,
    Sidebar,
    MenuIcon
  },
  watch: {
    sidebarOpen: value => {
      console.log("sidebarOpen:", value)
    }
  }
})
export default class Admin extends Vue {
  sidebarOpen = false
}
</script>
