
import {Options, prop, Vue} from "vue-class-component";
import {MenuIcon, XIcon} from "heroicons-vue3/outline";
import {Dialog, DialogOverlay, TransitionChild, TransitionRoot} from '@headlessui/vue'
import {EdisCloudIcon, EdisDashboardIcon, EdisEarthIcon, EdisMailIcon, EdisRackIcon, EdisWandIcon} from '@/common/icons'

class Props {
  sidebarOpen = prop<boolean>({ default: false })
}

@Options({
  components: {
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
    MenuIcon,
    XIcon,
    EdisDashboardIcon,
    EdisMailIcon
  },
  watch: {
    sidebarOpen: value => {
      console.log("sidebar open", value);
    }
  }
})
export default class Sidebar extends Vue.with(Props) {
  navigation = [
    { name: 'Dashboard', href: '/dashboard', icon: EdisDashboardIcon },
    { name: 'E-Mail', href: '/email', icon: EdisMailIcon },
    { name: 'Installer', href: '/installer', icon: EdisWandIcon },
    { name: 'Webspace', href: '/webspace', icon: EdisRackIcon },
    { name: 'Domains', href: '/domains', icon: EdisEarthIcon },
    { name: 'S3', href: '/amazon', icon: EdisCloudIcon },
  ]
}
