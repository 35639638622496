
import {Options, Vue} from "vue-class-component";
import { Header } from "@/common/components";
import Sidebar from "@/modules/sidebar/components/Sidebar.vue";
import {MenuIcon} from "heroicons-vue3/outline";

@Options({
  components: {
    Header,
    Sidebar,
    MenuIcon
  },
  watch: {
    sidebarOpen: value => {
      console.log("sidebarOpen:", value)
    }
  }
})
export default class Admin extends Vue {
  sidebarOpen = false
}
